import Lottie from "react-lottie-player"
import { set, get } from '../../reducers/data'
import { useDispatch, useSelector } from 'react-redux'

import { useState} from "react"

const GriLottie = ({animationData, style}) => {
    const data = useSelector(get)
    const play = data["play"] !== false

    return <div id={"gri-lottie"} className="lottie position-relative" style={style}>
        <Lottie
            loop={false}
            animationData={animationData}
            play={play}
            style={{ width: "100%", height: "100%" }}
        />
        {/* <input id="lottie-play-stop-button" aria-label="Avvia o interrompi animazione" type="button" value={ play ? "Stop" : "Play" } onClick={() => setPlay(!play)} /> */}
    </div>
}

export default GriLottie