import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { set, get } from '../../../reducers/data'
import { components } from 'react-select'
import AsyncSelect from "react-select/async"
import comuni from '../../../utils/comuni.json'

import check from "../../../icons/buttons/check.svg"

const nomiComuni = comuni.map(x => x.comune)
const comuniUnici = [...new Set(nomiComuni)]
const choices = comuniUnici.map(x => {
  return { value: x, label: x }
})

const AddressCAPCityComponent = () => {
  const [filteredChoices, setFilteredChoices] = useState([])
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [zipCodes, setZipCodes] = useState([])
  const [currentVal, setCurrentVal] = useState("")
  const data = useSelector(get)
  const dispatch = useDispatch()

  function filterAndSort(array, searchString) {
    const filteredArray = array.filter(element => element.value.toLowerCase().includes(searchString));
    filteredArray.sort((a, b) => {
      const startsWithA = a.value.startsWith(searchString);
      const startsWithB = b.value.startsWith(searchString);
      if (startsWithA && !startsWithB) return -1;
      if (!startsWithA && startsWithB) return 1;
      return a.value.length - b.value.length || a.value.localeCompare(b.value);
    });
  
    return filteredArray;
  }

  const handleFiltering = (val, cb) => {
    if (val.length < 3) return
    const filtered = filterAndSort(choices, val.toLowerCase())
    setFilteredChoices(
      filtered
    )
    setCurrentVal(val)
    if (cb) cb(filtered)
  }

  const getZipList = (val, cb) =>{
    if (cb) cb(zipCodes.filter(x => x.label.includes(val)))
  }

  const handleComune = val => {

    if (val === null){
      dispatch(
        set({
          cell: 'C14',
          value: undefined
        })
      )
      dispatch(
        set({
          cell: 'CAP',
          value: undefined
        })
      )
      setZipCodes([])
      return
    }
    dispatch(
      set({
        cell: 'C14',
        value: val
      })
    )

    dispatch(
      set({
        cell: 'CAP',
        value: undefined
      })
    )

    // setZipCodes(
    //   comuni
    //     .filter(x => x.comune === val)
    //     .map(x => {
    //       return { value: x.cap, label: x.cap }
    //     })
    // )

    // handleZipCode(comuni
    //   .filter(x => x.comune === val)
    //   .map(x => {
    //     return { value: x.cap, label: x.cap }
    //   })[0]?.value)
  }

  const handleZipCode = val => {
    dispatch(
      set({
        cell: 'CAP',
        value: val
      })
    )
  }

  const onFocus= ({ focused, isDisabled }) => {
    const msg = `Stai selezionando ${focused.label}${
      isDisabled ? ', disabilitata' : ''
    }`;
   
    return msg;
  };

  const  Option = props => {
    const { isSelected, innerProps, data } = props;
    const { tabIndex: _, ...rest } = innerProps;

    let className = "non-selected-opt"
    if (isSelected) className = "selected-opt"

    return (
      <components.Option {...props} innerProps={{
          ...rest,
          role: "option",
          "aria-selected": isSelected
        }}>
              <div
              role="option"
              aria-selected={isSelected}
              aria-label={data.label}
                className={className }
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "space-between",
                  padding: "4px",
                  width: "100%",
                  position: "relative",
                  zIndex: 2147483647,
                }}
              >
              <div aria-hidden={true} className="w-full d-flex justify-content-center align-items-center" >
                  {data.label}
              </div>
              {isSelected && <img aria-hidden={true} role="presentation" src={check} className="check-freccia" alt="" />}
          </div>
      </components.Option>)
  }

  return (
    <div className='city-cap-picker mb-16'>
      <h2 className='cap-title'>Dove si trova la tua casa?</h2>
      <div className='small-text'>
      Grazie a questa informazione possiamo considerare i fattori climatici, geografici o ambientali e proporti soluzioni più adatte e convenienti per te.
      </div>

      <div className='selectors'>
        <div className='select-box' style={{ maxWidth: "430px",  marginTop: "24px", paddingTop: "0"}}>
          <AsyncSelect
            className='city'
            placeholder={<span aria-hidden="true">Città</span>}
            aria-live="polite"
            aria-label='Scrivi la città'
            role="dialog"
            loadingMessage={() => "Caricamento..."}
            styles={{ paddingTop: data["C10"] ? 10 : 0, option: (base, state) => ({
              ...base,
              color: state.isSelected ? '#5738ff' : 'black',
              background: state.isFocused ? '#EFF2F7' : 'transparent', // TODO scegli el color al posto de grey
            }) }}
            onFocus={() => dispatch(set({
              cell: "focus",
              value: "indirizzo"
            }))}
            onBlur={() => dispatch(set({
              cell: "focus",
              value: undefined
            }))}
            ariaLiveMessages={{
              onFocus,
              guidance: () => currentVal?.length > 3 ? 'Premi le frecce su e giù per selezionare le opzioni' : "Scrivi almeno 3 lettere per cercare la città",
              onFilter: () => filteredChoices.length + ' opzioni disponibili',
              onChange: () => 'Città selezionata ' + data['C14']
            }}            
            loadOptions={handleFiltering}
            value={
              data['C14'] ? { value: data['C14'], label: data['C14'] } : null
            }
            noOptionsMessage={() => currentVal?.length > 3 ? 'Nessuna corrispondenza': "Inserisci almeno 3 caratteri"}
            menuIsOpen={isMenuOpen}
            onMenuClose={() => setIsMenuOpen(false)} 
            onMenuOpen={() => setIsMenuOpen(true)}
            components={{
              DropdownIndicator: null,
              Option
            }}
            onKeyDown={e => {
              if (e.key === "Enter") setIsMenuOpen(true)
              handleFiltering(
                e.target.value + (e.key.length === 1 ? e.key : '')
              )
              e.stopPropagation()
            }}
            onChange={e => handleComune(e?.value)}
          />
          {data['C14'] && <div aria-hidden={true} className='select-box-text' style={{ top: "7px", left: "18px", maxWidth: "600px"}}>Città</div>}
        </div>

        {/* <div className='select-box' style={{ maxWidth: "210px", marginTop: "24px", paddingTop: "0"}}>
          <AsyncSelect
            className='cap'
            placeholder={<span aria-hidden="true">CAP</span>}
            isSearchable={true}
            onKeyDown={e => {
              if (e.key === "Enter") setIsCAPMenuOpen(true);
              e.stopPropagation();
            }}
            aria-live="polite"
            role="dialog"
            styles={{ paddingTop: data["CAP"] ? 10 : 0, option: (base, state) => ({
              ...base,
              color: state.isSelected ? '#5738ff' : 'black',
              background: state.isFocused ? '#EFF2F7' : 'transparent', // TODO scegli el color al posto de grey
            }) }}
            // isClearable={true}
            ariaLiveMessages={{
              onFocus,
              guidance: () => 'Premi le frecce su e giù per selezionare le opzioni',
              onFilter: () => filteredChoices.length + ' opzioni disponibili',
              onChange: () => 'CAP selezionato ' + data['CAP']
            }}
            menuIsOpen={isCAPMenuOpen}
            onMenuClose={() => setIsCAPMenuOpen(false)} 
            onMenuOpen={() => setIsCAPMenuOpen(true)}
            aria-label='Seleziona o scrivi il CAP '
            aria-labelledby="CAP"
            defaultValue={data['CAP']}
            value={
              data['CAP'] ? { value: data['CAP'], label: data['CAP'] } : null
            }
            noOptionsMessage={() => "Nessuna corrispondenza"}
            components={{
              DropdownIndicator: null,
              Option
            }}
            onChange={e => handleZipCode(e.value)}
            loadOptions={getZipList}
            defaultOptions={zipCodes}
            // loadOptions={(val, cb) => cb(zipCodes)}
            // options={zipCodes}
          />
          {data['CAP'] && <div aria-hidden={true} className='select-box-text' style={{ top: "6px", left: "10px"}}>CAP</div>}
        </div> */}
      </div>
    </div>
  )
}

export default AddressCAPCityComponent
