import {heatType, hotWater, kitchen, rooms, split} from "./page1Images.js"
import * as animations from "../../../animations"
import {termico as termicoImg} from './page0Images'
import ContatoreComponent from "./ContatoreComponent.jsx";
import GriLottie from "../../dsy-inhouse/GriLottiePlayer.jsx";


const riscaldamento = {
    aria: heatType.aria,
    gpl: heatType.gas,
    gasolio: heatType.gasolio,
    legna: heatType.legna,
    metano: heatType.metano,
    pellet: heatType.pellet,
    style: {
        right: "32%",
        bottom: "40%",
        zIndex: "4",
        width: "3.8%"
    },
    focus: {
        img: animations.focusS,
        style: {
            right: "26%",
            bottom: "36%",
            zIndex: "5",
            width: "15%"
        }
    }
};

const acqua = {
    aria: hotWater.aria,
    gpl: hotWater.gas,
    gasolio: hotWater.gasolio,
    legna: hotWater.legna,
    metano: hotWater.metano,
    pellet: hotWater.pellet,
    style: {
        left: "16%",
        top: "54%",
        zIndex: "4",
        width: "3.8%"
    },
    focus: {
        img: animations.focusS,
        style: {
            left: "11%",
            top: "51%",
            zIndex: "5",
            width: "15%"
        }
    }
};

const cucina = {
    aria: {
        cook: kitchen.cook.gas,
        oven: kitchen.oven.aria
    },
    gpl: {
        cook: kitchen.cook.gas,
        oven: kitchen.oven.gas
    },
    metano: {
        cook: kitchen.cook.gas,
        oven: kitchen.oven.metano
    },
    elettrico: {
        cook: null,
        oven: kitchen.oven.elettrico
    },
    style: {
        cook: {
            left: "30%",
            top: "49%",
            zIndex: "4",
            width: "12%"
        },
        oven: {
            left: "37%",
            top: "54%",
            zIndex: "4",
            width: "4%"
        }
    },
    focus: {
        img: animations.focusS,
        style: {
            left: "32%",
            top: "51%",
            zIndex: "4",
            width: "15%"
        }
    }
};

const stanza = {
    default: rooms.default,
    pellet: rooms.pellet,
    legna: rooms.legna,
    centralizzato: rooms.centralizzato,
}

const termico = {
    img: termicoImg,
    style: {
        left: "0%",
        top: "27%",
        zIndex: "4",
        width: "18%"
    }
}

const contatore = {
    style: {
        zIndex: "4",
    }
}

const condizionatore = {
    img: split.splitAirco,
    style: {
        top:"30%",
        right: "25%",
        zIndex: "5",
        width: "13%",
    },
    ice: {
        img: split.spliIceIcon,
        style: {
            top: "35.5%",
            right: "25%",
            width: "2.5%",
            zIndex: "10",
        },
    },
    fire: {
        img: split.splitFireIcon,
        style: {
            top: "37.9%",
            right: "24.8%",
            width: "2.5%",
            zIndex: "12",
        },
    }
}

export default function handlePage1(data) { 

    if (data.screen === 2) {
        const amount = data["C61"].toLowerCase() === "si" ? parseInt(data["D61"]) : 0
        return [<ContatoreComponent style={contatore.style} amount={amount}/>]
    }
    
    const result = []
    // riscaldamento centralizzato o no
    const isCentralizzato = !!data['C24'];
    // tipo di riscaldamento
    const heatType = data["C25"]?.split(" ")[0]?.split("/")[0]?.toLowerCase()
    // tipo di riscaldamento acqua
    const waterType = data["C26"]?.split(" ")[0]?.split("/")[0]?.toLowerCase()
    // tipo di energia della cucina
    const kitchenType = data["C27"]?.split(" ")[0]?.split("/")[0]?.toLowerCase()
    // solare / termico
    const isTermico = !!data['C29'];
    // condizionatore
    const hasCondizionatore = !!data['C19'];
    const hasPompaDicalore = !!data["CondizionatoriRiscaldamento"];

    if (!!data['focus']) {
        if (data['focus'] === "C25") {
            result.push(<GriLottie animationData={riscaldamento.focus.img} style={riscaldamento.focus.style}/>)
        }
        if (data['focus'] === "C26") {
            result.push(<GriLottie animationData={acqua.focus.img} style={acqua.focus.style}/>)
        }
        if (data['focus'] === "C27") {
            result.push(<GriLottie animationData={cucina.focus.img} style={cucina.focus.style}/>)
        }
    }

    if (!isCentralizzato && !!heatType && !!riscaldamento[heatType] && !data['focus'] !== "C25") {
        result.push(<img src={riscaldamento[heatType]} style={riscaldamento.style} alt="" role="presentation" />)
    }

    if (!!waterType && !!acqua[waterType] && !data['focus'] !== "C26") {
        result.push(<img src={acqua[waterType]} style={acqua.style} alt="" role="presentation" />)
    }

    if (!!kitchenType && !!cucina[kitchenType] && !data['focus'] !== "C27") {
        if (!!cucina[kitchenType].cook) {
            result.push(<img src={cucina[kitchenType].cook} style={cucina.style.cook} alt="" role="presentation" />)
        }
        if (!!cucina[kitchenType].oven) {
            result.push(<img src={cucina[kitchenType].oven} style={cucina.style.oven} alt="" role="presentation" />)
        }
    }

    if (hasCondizionatore) {
        result.push(<img style={condizionatore.style} src={condizionatore.img} role="presentation" alt="" />)
        result.push(<img style={condizionatore.ice.style} src={condizionatore.ice.img} role="presentation" alt="" />)
        if (hasPompaDicalore) {
            result.push(<img style={condizionatore.fire.style} src={condizionatore.fire.img} role="presentation" alt="" />)
        }
    }

    if (isTermico) {
        result.push(<img src={termico.img} style={termico.style} alt="" role="presentation" />)
    }

    const room_background = isCentralizzato ? stanza.centralizzato : (stanza[heatType] || stanza[waterType] || stanza.default);
    // base image of the room
    result.push(<img src={room_background} alt="" role="presentation" />)
    
    return result
}