import { Modal, Container} from 'react-bootstrap';
import { GriButton } from '../../dsy-inhouse/GriButtonComponent';

function ProductModalComponent({ show, chiudi, extraTitle, serie, tags, description, image, footnote }) {


    return <Modal  keyboard={true}
            autoFocus={true}
            show={show} 
            scrollable={true}
            centered={true}
            role='radiogroup'
            aria-label={"Dettagli  " + extraTitle + " " + description}
            className="select-popup d-lg-none product-modal"
            animation={false}
            fullscreen={true} 
            backdrop={true}>
                <div aria-hidden={true} style={{ minHeight: "100vh"}} className='d-flex flex-column justify-content-between'>
                <div className="box-component yellow-tags no-border">
                    <div className="bg-secondary d-flex justify-content-center mb-3">
                    {image && <img src={image} className="w-100" alt="" role="presentation" />}
                </div>
           <div className="d-flex align-items-center mb-3">
               
                <div className={"w-100"}> 
                <div className="f14 bold"> {extraTitle}</div>
                <div className="serie" dangerouslySetInnerHTML={{ __html: serie}}></div>
                {tags &&<div className="tags">
                    {tags.map((tag, i) => 
                        <div key={i} className="tag">{tag}</div>
                    )}
                </div>}
                </div>
            </div>
            <p className="description mb-0 f14 lh150" dangerouslySetInnerHTML={{ __html: description}}></p>
            
            </div>
          
            <Container>
            { footnote && <p className="small-note">{footnote}</p>}
                <GriButton className="w-100 mb-3" 
                    onClick={chiudi} 
                    variant="primary"
                    label={"Apri scheda dettagli prodotto"} >Ok!</GriButton>
                 
            </Container>
            </div>
    </Modal>
}

export default ProductModalComponent;
