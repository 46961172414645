import italy from "../../../icons/banner/italy.png"
import landingHome from "../../../icons/banner/landing-home.png"
import esterno from "../../../icons/banner/esterno.png"

// houses
import monofamiliare from "../../../icons/banner/case/monofamiliare.svg"
import bifamiliare from "../../../icons/banner/case/bifamiliare.svg"
import piccolo from "../../../icons/banner/case/piccolo.svg"
import medio from "../../../icons/banner/case/medio.svg"
import grande from "../../../icons/banner/case/grande.svg"
// zolla
import zolla1 from "../../../icons/banner/zolla/zolla1.svg"  
import zollaPin from "../../../icons/banner/zolla/zolla-pin.svg"  
// balcony
import ringhiera from "../../../icons/banner/balconi/ringhiera.svg"
import muratura from "../../../icons/banner/balconi/muratura.svg"
// accesories
import coibentato from "../../../icons/banner/accessori/coibentato.svg"
import ristrutturato from "../../../icons/banner/accessori/ristrutturato.svg"
import fotovoltaico from "../../../icons/banner/accessori/fotovoltaico-antenna.svg"
import termico from "../../../icons/banner/accessori/termico-antenna.svg"
// AC
import airco from "../../../icons/banner/condizionatori/airco.svg"
import acHeat from "../../../icons/banner/condizionatori/airco-heat.svg"
import aircoMirror from "../../../icons/banner/condizionatori/airco-mirror.svg"
import acHeatMirror from "../../../icons/banner/condizionatori/airco-heat-mirror.svg"

//people
import people_right_1 from "../../../icons/banner/people/right/people-1.svg"
import people_right_2 from "../../../icons/banner/people/right/people-2.svg"
import people_right_3 from "../../../icons/banner/people/right/people-3.svg"
import people_right_4 from "../../../icons/banner/people/right/people-4.svg"
import people_right_5 from "../../../icons/banner/people/right/people-5.svg"
import people_right_6 from "../../../icons/banner/people/right/people-6.svg"
import people_right_7 from "../../../icons/banner/people/right/people-7.svg"
import people_right_8 from "../../../icons/banner/people/right/people-8.svg"
import people_right_9 from "../../../icons/banner/people/right/people-9.svg"
import people_right_10 from "../../../icons/banner/people/right/people-10.svg"
import people_right_11 from "../../../icons/banner/people/right/people-11.svg"
import people_right_12 from "../../../icons/banner/people/right/people-12.svg"
import people_right_13 from "../../../icons/banner/people/right/people-13.svg"
import people_right_14 from "../../../icons/banner/people/right/people-14.svg"
import people_right_15 from "../../../icons/banner/people/right/people-15.svg"
import people_right_16 from "../../../icons/banner/people/right/people-16.svg"
import people_right_17 from "../../../icons/banner/people/right/people-17.svg"
import people_right_18 from "../../../icons/banner/people/right/people-18.svg"
import people_right_19 from "../../../icons/banner/people/right/people-19.svg"
import people_right_20 from "../../../icons/banner/people/right/people-20.svg"

import people_left_1 from "../../../icons/banner/people/left/people-1.svg"
import people_left_2 from "../../../icons/banner/people/left/people-2.svg"
import people_left_3 from "../../../icons/banner/people/left/people-3.svg"
import people_left_4 from "../../../icons/banner/people/left/people-4.svg"
import people_left_5 from "../../../icons/banner/people/left/people-5.svg"
import people_left_6 from "../../../icons/banner/people/left/people-6.svg"
import people_left_7 from "../../../icons/banner/people/left/people-7.svg"
import people_left_8 from "../../../icons/banner/people/left/people-8.svg"
import people_left_9 from "../../../icons/banner/people/left/people-9.svg"
import people_left_10 from "../../../icons/banner/people/left/people-10.svg"
import people_left_11 from "../../../icons/banner/people/left/people-11.svg"
import people_left_12 from "../../../icons/banner/people/left/people-12.svg"
import people_left_13 from "../../../icons/banner/people/left/people-13.svg"
import people_left_14 from "../../../icons/banner/people/left/people-14.svg"
import people_left_15 from "../../../icons/banner/people/left/people-15.svg"
import people_left_16 from "../../../icons/banner/people/left/people-16.svg"
import people_left_17 from "../../../icons/banner/people/left/people-17.svg"
import people_left_18 from "../../../icons/banner/people/left/people-18.svg"
import people_left_19 from "../../../icons/banner/people/left/people-19.svg"
import people_left_20 from "../../../icons/banner/people/left/people-20.svg"

import people_group_1 from "../../../icons/banner/people/group/people-1.svg"
import people_group_2 from "../../../icons/banner/people/group/people-2.svg"
import people_group_3 from "../../../icons/banner/people/group/people-3.svg"
import people_group_4 from "../../../icons/banner/people/group/people-4.svg"
import people_group_5 from "../../../icons/banner/people/group/people-5.svg"
import people_group_6 from "../../../icons/banner/people/group/people-6.svg"
import people_group_7 from "../../../icons/banner/people/group/people-7.svg"
import people_group_8 from "../../../icons/banner/people/group/people-8.svg"
import people_group_9 from "../../../icons/banner/people/group/people-9.svg"
import people_group_10 from "../../../icons/banner/people/group/people-10.svg"
import people_group_11 from "../../../icons/banner/people/group/people-11.svg"
import people_group_12 from "../../../icons/banner/people/group/people-12.svg"
import people_group_13 from "../../../icons/banner/people/group/people-13.svg"
import people_group_14 from "../../../icons/banner/people/group/people-14.svg"
import people_group_15 from "../../../icons/banner/people/group/people-15.svg"
import people_group_16 from "../../../icons/banner/people/group/people-16.svg"
import people_group_17 from "../../../icons/banner/people/group/people-17.svg"
import people_group_18 from "../../../icons/banner/people/group/people-18.svg"
import people_group_19 from "../../../icons/banner/people/group/people-19.svg"
import people_group_20 from "../../../icons/banner/people/group/people-20.svg"


const people = {
    right: {
        1: people_right_1,
        2: people_right_2,
        3: people_right_3,
        4: people_right_4,
        5: people_right_5,
        6: people_right_6,
        7: people_right_7,
        8: people_right_8,
        9: people_right_9,
        10: people_right_10,
        11: people_right_11,
        12: people_right_12,
        13: people_right_13,
        14: people_right_14,
        15: people_right_15,
        16: people_right_16,
        17: people_right_17,
        18: people_right_18,
        19: people_right_19,
        20: people_right_20
    },
    left: {
        1: people_left_1,
        2: people_left_2,
        3: people_left_3,
        4: people_left_4,
        5: people_left_5,
        6: people_left_6,
        7: people_left_7,
        8: people_left_8,
        9: people_left_9,
        10: people_left_10,
        11: people_left_11,
        12: people_left_12,
        13: people_left_13,
        14: people_left_14,
        15: people_left_15,
        16: people_left_16,
        17: people_left_17,
        18: people_left_18,
        19: people_left_19,
        20: people_left_20
    },
    group: {
        1: people_group_1,
        2: people_group_2,
        3: people_group_3,
        4: people_group_4,
        5: people_group_5,
        6: people_group_6,
        7: people_group_7,
        8: people_group_8,
        9: people_group_9,
        10: people_group_10,
        11: people_group_11,
        12: people_group_12,
        13: people_group_13,
        14: people_group_14,
        15: people_group_15,
        16: people_group_16,
        17: people_group_17,
        18: people_group_18,
        19: people_group_19,
        20: people_group_20
    }

}

export {
    italy,
    landingHome,
    esterno,
    monofamiliare,
    bifamiliare,
    piccolo,
    medio,
    grande,
    zolla1,
    zollaPin,
    ringhiera,
    muratura,
    coibentato,
    ristrutturato,
    fotovoltaico,
    termico,
    airco,
    acHeat,
    aircoMirror,
    acHeatMirror,
    people
}
