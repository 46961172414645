import * as images from './page3Images.js'
import pigAnimation from "../../../animations/Maialino.json"
import GriLottie from '../../dsy-inhouse/GriLottiePlayer.jsx'

export default function handlePage3(data) {
    return [
        <div className="maialino-risparmio">
            <GriLottie animationData={pigAnimation}/>
        </div>
    ]
}