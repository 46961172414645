import a from "../../../icons/banner/energy-flags/a.svg" 
import a2 from "../../../icons/banner/energy-flags/a2.svg" 
import a3 from "../../../icons/banner/energy-flags/a3.svg" 
import a4 from "../../../icons/banner/energy-flags/a4.svg" 
import b from "../../../icons/banner/energy-flags/b.svg" 
import c from "../../../icons/banner/energy-flags/c.svg" 
import d from "../../../icons/banner/energy-flags/d.svg" 
import e from "../../../icons/banner/energy-flags/e.svg" 
import f from "../../../icons/banner/energy-flags/f.svg" 
import g from "../../../icons/banner/energy-flags/g.svg" 
import classe from "../../../icons/banner/energy-flags/classe.svg" 


export const energyIcon = (energyClass) => {
    switch (energyClass.toLocaleLowerCase()) {
        case "a1": return a
        case "a2": return a2
        case "a3": return a3
        case "a4": return a4
        case "b": return b
        case "c": return c
        case "d": return d
        case "e": return e
        case "f": return f
        case "g": return g
        default:
            return classe
    }
}

export default function EnergyFlagComponent ({energyClass, style}) {
    style = !!style ? style : {}
    return (
        <img src={energyIcon(energyClass)} alt="logo" style={style} />
    )
}
