import CashPileGroupComponent from "./CashPileGroupComponent"
import LampadineMatrix from "./LampadineMatrix";

export default function handlePage2(data) {

    if (data.screen === 1) {

        const result = []
        const unknown = data["C31"].toLocaleLowerCase() === "no"

        // in base a quante pile ho metto i margini
        const rowStyles = {
            1: {
                top: 20,
                right: 5,
                width: "50%",
                yMargin: 0,
                xMargin: 0,
                pilesGap: 5
            },
            2: {
                top: 18,
                right: 28,
                width: "40%",
                yMargin: 18,
                xMargin: 35,
                pilesGap: 2
            },
            3: {
                top: 18,
                right: 38,
                width: "30%",
                yMargin: 12,
                xMargin: 22,
                pilesGap: 1
            },
            4: {
                top: 0,
                right: 20,
                width: "30%",
                yMargin: 13,
                xMargin: 23,
                pilesGap: 0
            }
        }

        // all power sources
        const dataControl = [
            { key: "elettrico" , value: "C33", energy: null},
            { key: "C25" , value: "F25", energy: null},
            { key: "C26" , value: "F26", energy: null},
            { key: "C27" , value: "F27", energy: null},
        ]

        // get the active energy power sources
        const dataControlActive = dataControl.reduce((acc, control) => {
            const energy = (data[control.key] || control.key)?.split(" ")[0]?.split("/")[0]?.toLowerCase()
            const _control = {...control, energy}
            const exists = acc.find(c => c.energy === energy)
            if (!exists) {
                acc.push(_control)
            }
            return acc;
        }, [])

        for (let i = 0; i < dataControlActive.length; i++) {
            const control = dataControlActive[i]
            let value  = data[control.value]
            const styles = rowStyles[dataControlActive.length]
            let index = i;
            
            if (dataControlActive.length === 4 && i > 1) {
                styles.top = 35;
                styles.right = 35
                index = i - 2
            }
            const style = {
                zIndex: 1,
                width: styles.width,
                top: `${styles.top + (index * styles.yMargin)}%`, 
                right: `${styles.right - (index * styles.xMargin)}%`
            }

            value = unknown  ? null : (!!value ? parseInt(value) : 0)
            result.push(
                <CashPileGroupComponent energyType={control.energy} amount={value} style={style} pilesGap={styles.pilesGap} index={i}/>
            )
        }
        return result;
    }

    if (data.screen === 2) {
        const amount = !!data["C34"] && data["C34"].toLocaleLowerCase() === "no" ? null : parseInt(data["C35"]);
        return [
            <LampadineMatrix amount={amount}/>
        ]
    }

    return []
}