import * as images from './page3Images.js'
import { addPercentageToStyle } from "./bannerUtils"

const lefGap=0
const topGap=0

function SolutionBox({name, selected, style}) {

    if (!images.boxes.selected[name] || !images.boxes.grey[name]) {
        return []
    }

    return [
        <div className="solution-box">
        {
            !!selected ?
                <img src={images.boxes.selected[name]} style={style} alt="" role="presentation" /> :
                <img src={images.boxes.grey[name]} style={style} alt="" role="presentation" />
        }
        </div>
    ]
}

export default function handlePage4(data) {

    const commonBoxStyle = {
        width: "30%"
    }

    const boxesMap = {
        fire: {
            key: "C41",
            style: {
                left: "5%",
                top: "15%"
            }
        },
        water: {
            key: "",
            style: {
                top: "15%",
                left: "35%"
            }
        },
        cook: {
            key: "C48",
            style: {
                left: "65%",
                top: "15%"
            }
        },
        fridge: {
            key: "Final_Frigorifero",
            style: {
                top: "45%",
                left: "5%"
            }
        },
        wash: {
            key: "Final_Lavatrice",
            style: {
                left: "35%",
                top: "45%"
            }
        },
        dry: {
            key: "Final_Asciugatrice",
            style: {
                left: "65%",
                top: "45%"
            }
        },
        tv: {
            key: "Final_TV",
            style: {
                top: "75%",
                left: "20%"
            }
        },
        sun: {
            key: "C53",
            style: {
                left: "50%",
                top: "75%"
            }
        },
    }

    return [
        <div className="solution-boxes-container">
            {
            Object.keys(boxesMap)
                .map((name) => {
                    const selected = !!data[boxesMap[name].key];
                    const style = addPercentageToStyle(addPercentageToStyle({...commonBoxStyle, ...boxesMap[name].style}, 'left', lefGap), 'top', topGap)
                    return <SolutionBox key={name} name={name} style={style} selected={selected}/>
                })
            }
        </div>]
}