import {boxes} from './page3Images'

export default function handlePage5(data, pageTitle) {
    const st = {
        top: "20%"
    }
    switch(pageTitle){
        case "TV": 
        if (data["Final_TV"] === "Si") 
            return [<img src={boxes.grey.tv} style={st} alt="" role="presentation" />]
        else 
            return [<img src={boxes.big.tv} style={st} alt="" role="presentation" />]
        case "Frigorifero":
            return [<img src={boxes.big.fridge} style={st} alt="" role="presentation" />]
        case "Lavatrice":
            return [<img src={boxes.big.wash} style={st} alt="" role="presentation" />]
        case "Asciugatrice":
            return [<img src={boxes.big.dry} style={st} alt="" role="presentation" />]
        case "Riscaldamento":
            return [<img src={boxes.big.fire} style={st} alt="" role="presentation" />]
        case "Autoproduzione energia":
        case "Solare Virtuale":
        case "Fotovoltaico":
        case "Energia solare":
                return [<img src={boxes.big.sun} style={st} alt="" role="presentation" />]
        case "Potenza contatore":
        default: 
            return []
    }
}