import React from 'react';
import { DsyDivider, DsyIcon } from '../../../dsy-wrappers';
import BlueBoxComponent from '../reusable/BlueBoxComponent';
import InfoButtonComponent from '../info-button/InfoButtonComponent';

function Box ({power, description}) { 
    return <div className="box">
        <div className="power">{power} kW</div>
        <div className="description">{description}</div>
    </div>
}

function AumentoContatoreComponent({ from, to, extraClass }) {

    const  superiore = "In base alla previsione dei tuoi nuovi consumi, ti suggeriamo la nuova potenza del tuo contatore:"
    const  inferiore = "In base alle informazioni raccolte, sembra che il tuo consumo energetico sia minore rispetto alla potenza disponibile. Per avere un risparmio economico, quindi, ti suggeriamo di ridurre la potenza del tuo contatore:"

    const infoSuperiore = {
          title:"Perché ti proponiamo una nuova potenza?", 
          subtitle:"È probabile che tu abbia scelto per la tua casa nuove soluzioni o reso più efficienti quelle esistenti. <b>Per garantirne il corretto funzionamento, è necessario adeguare la potenza del contatore</b>, così da gestire il carico energetico ed evitare interruzioni. <br /> <br /> Questo intervento, qualora ci fosse <b>bisogno di un incremento energetico sostanziale, </b> potrebbe richiedere il cambio del contatore stesso. Ma non preoccuparti, nel caso, sarai supportato da un nostro tecnico."
    }
    const infoInferiore = {
        title:"Perché ti proponiamo di ridurre la potenza?", 
        subtitle:"È probabile che <b>i tuoi consumi non raggiungano la potenza massima disponibile</b>, o che tu abbia scelto delle soluzioni più efficienti rispetto a quelle precedenti. Per queste ragioni, <b>ti suggeriamo di ridurre la potenza del contatore</b>.<br><br>Riducendo la potenza, il costo fisso della bolletta diminuisce, permettendoti di <b>risparmiare</b> sulle spese mensili."
    }
    const info = from > to ? infoInferiore : infoSuperiore;


    if (from === to ) return <>
     <div className={"aumento-contatore "+ extraClass }>
            <h2 className="bold-title mb-3">In base alla previsione dei tuoi nuovi consumi, ti suggeriamo di non cambiare la potenza del tuo contatore:</h2>
            <div className="d-flex align-items-center">
                <Box power={from} description={"Poternza attuale"} />
            </div>
            <div className="mt-5 mb-3 mw730" aria-hidden="true"><DsyDivider /></div>
        </div></>
    return (
        <>
        <div className={"aumento-contatore "+ extraClass }>
            <h2 className="bold-title mb-3">{from > to ? inferiore: superiore}</h2>
            <div className="d-flex align-items-center">
                <Box power={from} description={"Poternza attuale"} />
                <div className="mx-3"> <DsyIcon name="arrowForward" /></div>
                <Box power={to} description={"Nuova potenza suggerita"} />
                <div style={{ borderRight:"1px solid #C2CDDD", height: "48px", marginLeft: "1rem", marginRight: "0.5rem"}} ></div>
                <InfoButtonComponent { ...info } 
               />
           </div>
        </div>

        { from < to &&<BlueBoxComponent extraClassName="mt-4"> 
        Se richiedi un <b>aumento della potenza del contatore</b> dovrai pagare un <b>contributo una tantum </b>(cioè solo una volta) definito dal venditore in base al contratto. Inoltre, sarà previsto un <b>aumento dei costi ricorrenti in bolletta.</b> Sia il contributo una tantum che i costi ricorrenti in bolletta verranno cosiderati, nel risultato di questa simulazione, con <b>valori medi*</b><br /> <br />
        <div className="d-flex color-secondary align-items-center" ><a  target="_blank" rel="noreferrer" 
                aria-label="Apre una nuova scheda con ulteriori informazioni sulla lettura della bolletta della luce"
                href="https://vivielettrico.it/it/gestione-energia-casa/energia-elettrica/aumento-di-potenza">
        Costi ricorrenti in bolletta &nbsp;</a><DsyIcon name="arrow-right" size="14px" /></div>
        </BlueBoxComponent>}

        <div className="mt-5 mb-3 mw730" aria-hidden="true"><DsyDivider /></div>
        <div className="bold-title">
            Scegli la nuova potenza del tuo contatore
        </div>

        <div className="gen-subtitle ">
        Se il nostro suggerimento sulla nuova potenza del tuo contatore non ti convince, <br /> puoi modificarla come preferisci. 
        </div>
        </>
    );
}

export default AumentoContatoreComponent;