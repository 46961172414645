import {soldi} from "./page2Images"
import {clamp, traslateStyle, addPercentageToStyle} from "./bannerUtils"

const pileMin = 1;
const pileMax = 3;
const banknote = 100;
const stepsMax = 10;
const stepsMin = 1;
const pileCap = 999;

export default function CashPileGroupComponent ({energyType, amount, style, pilesGap = 0, index = null}) {
    if (!energyType || !soldi[energyType]) {
        return []
    }

    const clazz = "cashpile-group-component cashpile-group-" + index;

    if (amount === 0) {
        return [
            <div className={clazz}>
                <img src={soldi[energyType].grey} style={style} role="presentation" alt=""/>
            </div>
        ]
    }

    if (amount === null || amount === undefined) {
        return [
            <div className={clazz}>
                <img src={soldi[energyType].unknown} style={style} role="presentation" alt=""/>
            </div>
        ]
    }


    const piles = [];
    const nPiles = clamp(((amount / banknote) / stepsMax), pileMin, pileMax);

    for (let i = 0; i < nPiles; i++) {
        const key = parseInt(clamp((amount - (i * pileCap)) / banknote, stepsMin, stepsMax))
        const icon = soldi[energyType][key]

        const newStyle = addPercentageToStyle(addPercentageToStyle(style, "top", i * (6 + pilesGap)), "right", i * (14 + pilesGap));
        piles.push(<img src={icon} style={newStyle} className={"cashpile cashpile-" + i + ""} role="presentation" alt=""/>)
    }
    return [
        <div className={clazz}>
            {piles}
        </div>
    ];


    

}