import React from 'react';
import BoxComponent from '../reusable/BoxComponent';
import { DsyDivider } from '../../../dsy-wrappers';
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import SelectAtViewComponent from '../selects/SelectAtView';
import night from "../../../icons/icon/new/night_b.svg"
import ProductBox from '../reusable/ProductBoxComponent';

function BoxFotoVirtualeComponent() {
    const dispatch = useDispatch();
    const data = useSelector(get);
    return (
        <div className="foto-box-virtuale">
            <ProductBox 
             disabled={data["ConsumoOrario"] === false}
             title="Solare Virtuale"
             serie="Offerta Luce"
             tags={["Energia solare/mese: 130kWh", "1.0kW"]}
             image={"FotoVirtuale"}
             bottom={[{
                title: "Componente energia",
                big: "0,000",
                comma: "€/KWh*",
                sub: "dalle 10 alle 17"
             }, {
                title: "Fino a 130kWh/mese e oltre la soglia di 130kWh/mese",
                big: "0,165",
                comma: "€/KWh*",
                sub: "dalle 17 alle 10"
             }, {
                title: "Canone mensile",
                big: "12,00",
                comma: "€/POD al mese"
             },
            {
                title: "CCV",
                big: "10,00",
                comma: "€/POD al mese"
            },
            {
                title: "Costo iniziale",
                big: "99,00",
                comma: "€"
            }]}
               description={"Ideale per chi non può installare un pannello fotovoltaico a casa o vuole consumare energia solare certificata.  <b>La componente energia scontata al 100% tutti i giorni dalle 10 alle 17</b>, fino a un massimo di <b>130 kWh al mese</b>, equivalenti alla produzione di un pannello da 1 kW**. Superati i 130 kWh mensili nelle ore solari (10-17), la componente energia avrà lo stesso prezzo applicato in base al consumo nelle ore non solari, ovvero 0,165 €/kWh. Inoltre, il canone mensile, la CCV e il costo della componente energia rimarranno bloccati per 3 anni! Grazie al contatore 2G e all’app Enel Energia, puoi monitorare i tuoi consumi in ogni momento."}
            >
                
            </ProductBox>
            {/* <BoxComponent 
                extraClass={" yellow-tags "}
                disabled={data["ConsumoOrario"] === false}
                title="Solare Virtuale"
                serie="fotovoltaico virtuale"
                tags={["Energia solare/mese: 130kWh", "1.0kW"]}
                image={"FotoVirtuale"}
                description={"Ideale per chi non può installare un pannello fotovoltaico a casa o vuole consumare energia solare certificata.  <b>La componente energia scontata al 100% tutti i giorni dalle 10 alle 17</b>, fino a un massimo di <b>130 kWh al mese</b>, equivalenti alla produzione di un pannello da 1 kW**. Superati i 130 kWh mensili nelle ore solari (10-17), la componente energia avrà lo stesso prezzo applicato in base al consumo nelle ore non solari, ovvero 0,165 €/kWh. Inoltre, il canone mensile, la CCV e il costo della componente energia rimarranno bloccati per 3 anni! Grazie al contatore 2G e all’app Enel Energia, puoi monitorare i tuoi consumi in ogni momento."}
                 bottomChildren={    
                 <div className="pricing-box d-flex d-lg-none justify-content-between align-items-end flex-column text-start">
                     <p className="f14 mb-0">Componente energia</p>
                     <p className="bold f24 mb-1 bb1 mb-2">0,000 € <span className="f14">/ kWh dalle 10 alle 17</span></p>
                     <p className="f14 mb-0">Fino a 130kWh/mese</p>
                     <p className="bold f24 mb-1 mb-2">0,165 € <span className="f14">/ kWh* dalle 17 alle 10</span></p>
                     <p className="f14 bb1 mb-2">e oltre la soglia di 130kWh/mese</p>
                     <p className="f14 mb-0">Canone mensile</p>
                     <p className="bold f24 mb-1 bb1 mb-2">12,00 € <span className="f14">/ POD al mese</span></p>
                     <p className="f14 mb-0">CCV</p>
                     <p className="bold f24 mb-1 bb1 mb-2">10,00 € <span className="f14">/ POD al mese</span></p>
                     <p className="f14 mb-0">Costo iniziale</p>
                     <p className="bold f24 mb-0 bb1  mb-2">99,00 €</p>
                     <p className="f12 mb-3">IVA inclusa</p>
                 </div>}
                 topChildren={
                    <div className="d-none d-lg-flex justify-content-between align-items-end flex-column text-end pricing-box">
                         <p className="f14 mb-0">Componente energia</p>
                        <p className="bold f24 mb-1 bb1 mb-2">0,000 € <span className="f14">/ kWh dalle 10 alle 17</span></p>
                        <p className="f14 mb-0">Fino a 130kWh/mese</p>
                        <p className="bold f24 mb-1 mb-2">0,165 € <span className="f14">/ kWh* dalle 17 alle 10</span></p>
                        <p className="f14 bb1 mb-2">e oltre la soglia di 130kWh/mese</p>
                        <p className="f14 mb-0">Canone mensile</p>
                        <p className="bold f24 mb-1 bb1 mb-2">12,00 € <span className="f14">/ POD al mese</span></p>
                        <p className="f14 mb-0">CCV</p>
                        <p className="bold f24 mb-1 bb1 mb-2">10,00 € <span className="f14">/ POD al mese</span></p>
                        <p className="f14 mb-0">Costo iniziale</p>
                        <p className="bold f24 mb-0 bb1  mb-2">99,00 €</p>
                        <p className="f12 mb-3">IVA inclusa</p>
                   </div>}
                 >
                
            </BoxComponent> */}

            <div className="my-4" aria-hidden="true"><DsyDivider /></div>

            <h2 className="bold-title">Consumi almeno il 40% della tua energia elettrica tra le 10 e le 17?</h2>
            <p className="gen-subtitle">Vogliamo verificare se questa soluzione potrebbe essere conveniente per te</p>

            <SelectAtViewComponent 
                cell="ConsumoOrario"
                choices={[{
                    "text": "Si, consumo almeno il 40% in questi orari",
                    "value": true,
                    "image": 5
                },{
                    "text": "No, consumo meno del 40% in questi orari",
                    "value": false,
                    "image": 6
                }]}
            />

            { data["ConsumoOrario"] === false && <div className="d-flex blue-box f14">
                <img src={night} role="presentation" alt="" className="me-3" />
                <div>Sembra che la tua casa <b>consumi energia</b> principalmente al di <b>fuori delle ore solari</b>, quindi il <b>Solare Virtuale potrebbe non essere la scelta più conveniente per te</b>. Ma non preoccuparti, abbiamo <b>altre soluzioni per rendere la tua casa più efficiente</b> dal punto di vista energetico. Vai avanti e scopri come puoi migliorare la tua casa con le nostre altre proposte!
                </div>
            </div>}
            <>
                <div className="mt-4 mb-2">
                    <DsyDivider />
                </div>
                <div className="small-note f12">
                    <p>* Il prezzo si riferisce alla sola componente energia (IVA e imposte escluse) ed è bloccato per i primi 36 mesi. Tale prezzo è comprensivo delle perdite di rete che sono applicate come definite, pubblicate e aggiornate da ARERA. L’extra-consumo nelle ore solari (10-17) avrà il prezzo a consumo della componente energia delle ore non solari (17-10).</p>
                    <p>** La produzione indicata è una produzione media annua nazionale, tiene in considerazione le oscillazioni giornaliere e geografiche del meteo. Fonte: Stima di produzione PVGIS (Roma, Esposizione SUD, Perdite di sistema 14%, Inclinazione 25 gradi.</p>
                    {/* <p><b>* Esempio di finanziamento per i non clienti Enel.</b> Fino a 120 mesi ‐ prima rata a 60 giorni ‐ importo finanziabile da € 580 a € 1.833,70. Esempio Samsung TV Crystal UHD 43”: € 508,00 (importo totale del credito) in 24 rate da €22,99 ‐ TAN fisso 7,50% TAEG 7,71%. Il TAEG rappresenta il costo totale del credito espresso in percentuale annua e include: interessi, spesa mensile gestione pratica € 0; importo totale dovuto (importo totale del credito + costo totale del credito) € 551,76 Messaggio pubblicitario con finalità promozionale. Per le informazioni precontrattuali richiedere sul punto vendita il documento “Informazioni europee di base sul credito ai consumatori” (SECCI) e copia del testo contrattuale. Salvo approvazione Agos Ducato S.p.A. Enel Energia opera quale intermediario del credito NON in esclusiva. L'importo del finanziamento dipende dal prezzo del bene come concordato tra cliente e Enel Energia in funzione di eventuali scontistiche applicate da Enel per l'adesione ad ulteriori offerte.</p>
                            <p><b> In più vantaggi esclusivi Se sei cliente Enel Energia o lo diventi: </b></p>
                                <ul>
                                    <li>Prodotti ad alta efficienza a un prezzo scontato</li>
                                    <li>Finanziamento a tasso zero TAN 0% TAEG 0%</li>
                                    <li>Bonus complessivo di 60€ nelle tue bollette luce</li>
                                    <li>Consegna al piano gratuita per tutti i prodotti</li>
                                </ul>
                                    <p><b>Sconto sui prodotti, finanziamento a tasso zero e consegna al piano gratuita per il TV riservati ai clienti</b>
                                    che hanno sottoscritto un contratto di fornitura di energia elettrica e/o gas naturale nel mercato libero con Enel Energia, escluse le offerte PLACET luce e gas.
                                    </p>
                                    <p><b>Il bonus in bolletta</b> sarà riconosciuto ai clienti che hanno sottoscritto un contratto di fornitura di energia elettrica e/o gas naturale nel mercato libero con Enel Energia, escluse le offerte Placet (luce) e offerte del Servizio a Tutele Graduali per i clienti domestici non vulnerabili. Il bonus sarà riconosciuto dopo l’approvazione da parte della finanziaria e a seguito della consegna del prodotto, e ripartito come segue: 20€ nella prima bolletta utile decorso un mese dalla consegna, 40€ nella prima bolletta utile decorsi 13 mesi dalla consegna.</p>` */}
                </div>
                {/* <p className="small f14 " style={{ color: "#667790"}}>
                * Il prezzo si riferisce alla sola componente energia (IVA e imposte escluse) ed è bloccato per i primi 36 mesi. Tale prezzo è comprensivo delle perdite di rete che sono applicate come definite, pubblicate e aggiornate da ARERA. L’extra-consumo nelle ore solari (10-17) avrà il prezzo a consumo della componente energia delle ore non solari (17-10).
                </p>
                <p className="small f14" style={{ color: "#667790"}}>
                ** La produzione indicata è una produzione media annua nazionale, tiene in considerazione le oscillazioni giornaliere
                e geografiche del meteo. Fonte: Stima di produzione PVGIS (Roma, Esposizione SUD, Perdite di sistema 14%,
                Inclinazione 25 gradi. </p> */}
            </>
   
        </div>
    );
}

export default BoxFotoVirtualeComponent;