import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import React, { useState, useRef } from 'react';

import SelectButtonComponent from '../dropdown/SelectButtonComponent';
import climaAi from "../../../utils/climaai.json"
import InfoButtonComponent from '../info-button/InfoButtonComponent';
import { DsyDivider } from '../../../dsy-wrappers';
import BoxComponent from '../reusable/BoxComponent';
import DisclaimerComponent from '../reusable/DisclaimerComponent';
import StepperComponent from '../reusable/StepperComponent';
import { GriToggleComponent } from '../../dsy-inhouse/GriToggleComponent';
import ProductBoxComponent from '../reusable/ProductBoxComponent';

const ConfigSwitchComponent = ({ cell, data, text, onClick, activationMessage, info }) => {
    let currentValue = data[cell] ?? false
    if (cell === "NumeroClimatizzatori" && data[cell] !== false && data[cell] !== undefined && data[cell] !== null) currentValue = true
   

    return(
    <div
    // role="switch"
    // aria-roledescription={'interruttore per ' + text}
    // aria-checked={currentValue === true}
    // onKeyDown={(e) => {if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) onClick()}} 
    className={"switch-component config-riscaldamento px-0 d-flex align-items-center justify-content-between w-100 plain no-border " + (data ? "filled " : "") }>
        <label
        aria-hidden={true}
        className={"switch-text clickable " + (currentValue === true ? "bold": "") } 
            style={{ color: "#131416"}}
            onClick={onClick}
            >
       {text}
    </label>
    {info && <div className="d-block d-lg-none justify-content-center align-items-center p-static me-3 pe-3 mobile" style={{ borderRight: "1px solid #C2CDDD"}}> <InfoButtonComponent  {...info} /></div>}
    <GriToggleComponent  
        name={text}
        aria-checked={currentValue && currentValue !== "Non so"}
        checked={currentValue && currentValue !== "Non so"}
        onKeyDown={(e) => { 
            if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) onClick(e)
        }}
        onChange={onClick} />
    {/* <DsyToggle variant="apple" 
        aria-checked={currentValue && currentValue !== "Non so"}
        tabIndex={0}
        dsyValue={currentValue && currentValue !== "Non so"}
        onKeyDown={(e) => { 
            if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) onClick(e)
        }}
        size="small"
        handleChange={onClick}
    ></DsyToggle> */}
            <div id="liveRegion" 
                aria-live="polite" 
                className="visually-hidden">
               {currentValue !== false ? activationMessage : ""}</div>

    {info && <div className="d-none d-lg-flex justify-content-center align-items-center"> <InfoButtonComponent  {...info} /></div>}
</div>)
}

function ConfiguraRiscaldamentoComponent() {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const showRate = process.env.REACT_APP_RATE === "true"
    
    const defaultValues = {
        1: ["1 x motore", "1 x mono split"],
        2: ["1 x motore", "2 x mono split"],
        3: ["1 x motore", "3 x mono split"],
        4: ["2 x motore", "2 x dual split"],
        5: ["1 x motore dual split", "1 x motore trial split"],
        6: ["2 x motore", "3 x dual split"],
        7: ["1 x motore mono", "2 x motore trial split"],
        8: ["1 x motore dual", "2 x motore trial split"],
        9: ["3 x motore", "3 x trial split"]
        }

    let climaChoice = []
    console.log(data["NumeroClimatizzatori"], climaAi.filter(x => x.N == data["NumeroClimatizzatori"]).map(x => x.name))
    if (data["NumeroClimatizzatori"] === false || data["NumeroClimatizzatori"] === undefined) {
        climaChoice = climaAi.filter(x => x.N == data["C16"]).map(x => x.name)
    }
    else {
        climaChoice = climaAi.filter(x => x.N == data["NumeroClimatizzatori"]).map(x => x.name)
    }
    const defaultChoice = climaChoice[0] ?? "Nessuna configurazione"
    
    if (data["NumeroClimatizzatori"] === undefined || data["NumeroClimatizzatori"] === null) 
         dispatch(set({
             cell: "NumeroClimatizzatori",
             value: data["C16"]
         })) 

    const NumeroClimatizzatori = data["NumeroClimatizzatori"] ?? false
    const ModificaConfigurazione = data["ModificaConfigurazione"] ?? false

    const isDisabled = !(data["ScegliNumeroClimatizzatori"] === true || data["ScegliNumeroClimatizzatori"] === "Si")
    return (<>
    <div className={"mw730" + (!isDisabled ? " disa" : "")} aria-disabled={isDisabled} >
        <div className="bold-title mb-3">Per riscaldare la tua casa ti proponiamo:</div>
        
    <ProductBoxComponent
        extraClass={"yellow-tags "}
        image={"Pompa"}
        showDescription={true}
        title="Climatizzatore a pompa di calore"
        serie={"Quantità: <b>"+ Math.min(data["C16"],9)+"</b>"}
        tags={defaultValues[Math.min(data["C16"],9)]}
        description="Un climatizzatore a pompa di calore freddo/caldo è un <b>sistema di climatizzazione</b> in grado di <b>raffrescare e/o riscaldare</b> l'aria all'interno di un ambiente. La pompa di calore sfrutta l'energia termica presente nell'aria esterna e la utilizza per climatizzare l'interno, rendendola un'<b>opzione efficiente dal punto di vista energetico</b> sia per il raffrescamento che per il riscaldamento."
        children={showRate &&(<>
            <p className="f12 mb-0">A partire da</p>
            <div className="d-flex price-text justify-content-end mb-2"> 
        <p className="big"> 929 </p>
        <div className="d-flex flex-column small justify-content-between">
            <p className="f22 mb-0">,00 €*</p>
            <p className="f16 mb-1"> IVA Inclusa</p>
        </div>
    </div>
            <p className="f12 my-0">oppure paga a rate**</p>
        </>  )}
        
    
        />
    </div>
        {/* <div className={"mb-16 mt-16 " + (data["NumeroClimatizzatori"] !== false ? "disa" : "")} >
            <b>{data["C16"]} Climatizzatori a pompa di calore</b>
        </div>*/}

        <DisclaimerComponent 
            title="Vuoi configurare manualmente la tua soluzione per il riscaldamento?"
            subtitle="Potremmo aver bisogno di effettuare altri approfondimenti sulla tua casa: ci occorre sapere se ci sono stanze comunicanti, vincoli ambientali o tecnici. Non ti preoccupare, i nostri tecnici approfondiranno con te questi dettagli successivamente, se necessario." />

       <ConfigSwitchComponent 
            activationMessage={"E' ora possibile scegliere il numero di climatizzatori"}
            cell="ScegliNumeroClimatizzatori"
            text="Configura il climatizzatore a pompa di calore"
            data={data}
            info={{
                title: "Cosa puoi modificare nella configurazione", 
                subtitle: "Sceglierai il numero di <b>split</b>, ovvero le <b>unità interne</b> che distribuiscono l'aria all'interno dell'ambiente, e il <b>motore</b>, ossia <b>l'unità esterna</b> che alimenta il sistema e regola il funzionamento dell'intero climatizzatore. <br /> <br /> <b>La nostra proposta di configurazione si basa sulle informazioni che ci hai fornito riguardo la tua casa.</b> Puoi personalizzare liberamente il numero di split e motori che desideri."
            }}
            onClick={() => {
                        if (data["ScegliNumeroClimatizzatori"]) {
                            dispatch(set({
                                cell: "ScegliNumeroClimatizzatori",
                                value: false
                            }))
                        }
                        else {
                            dispatch(set({
                                cell: "ScegliNumeroClimatizzatori",
                                value: true
                            }))
                            dispatch(set({
                                cell: "NumeroClimatizzatori",
                                value: data["C16"]
                            }))
                        }
                    }} />

        <div className="disclaimer-component mt-3" aria-hidden="true"><DsyDivider /></div>

        { data["ScegliNumeroClimatizzatori"] === true && <>
            <StepperComponent 
            cell="NumeroClimatizzatori"
            text={"Split"}
            name={"Quanti split vorresti avere?"}
            info={
                {
                    title: "Cos'è uno split?",
                    subtitle: "È l'<b>unità interna</b> del climatizzatore, <b>quella che vedi dentro casa</b>. Questa parte distribuisce l'<b>aria fresca e/o calda</b> nella stanza. <br /> <br /> In modalità raffreddamento, lo split assorbe il calore dall'interno e lo trasferisce all'esterno, <b>tramite il suo collegamento con un’unità esterna, il motore. </b> In modalità riscaldamento, fa l'opposto, prelevando il calore dall'esterno e rilasciandolo all'interno."
                }
            }
            description={"Puoi selezionare fino ad un massimo di 9 split"}
            min={1} 
            step={1}
            max={9} 
            icon={"riscaldamento"}
            postChange={(current) => {
                dispatch(set({
                    cell: "C42",
                    value: climaAi.filter(x => x.N == current ).map(x => x.name)[0]
                }))
            }}
             />
    
            <div className="bold-title my-3 d-flex">Scegli la configurazione ideale di motore e split 
            <InfoButtonComponent  
                        title="Configurazione motore e split"
                        subtitle="Le opzioni qui proposte sono state già <b>filtrate in base al numero di split che hai inserito. </b> <br /> <br /> Per la categoria climatizzatori <b> il limite di configurazione massimo</b> di motore e split è di: <b>3 unità interne (split) + 1 unità esterna (motore)</b> per un <b>totale di 9 split</b>. Ciò significa che per raggiungere questo massimo <b>dovrai avere almeno 3 unità esterne (motore), con ciascuna che supporta fino a 3 split interni.</b>"
                        />
            </div>
            <div className={"d-flex align-items-center " } style={{ maxWidth:"430px"}}>
                <SelectButtonComponent 
                    extraClass={"flex-grow-1"}
                    name="Configurazione"
                    cell="C42"
                    key={NumeroClimatizzatori}
                    choices={climaChoice}
                    />

                
            </div>

        </>}

        {/* <SliderComponent 
            cell="NumeroClimatizzatori" 
            min={1} max={9} step={1} 
            unit="Climatizzatori a pompa di calore"
            
            type="range"
            forceDisabled={data["ScegliNumeroClimatizzatori"] !== true} />

        <h2 style={{ fontWeight: "unset", fontSize:"1rem"}} className="mt-16 pre">Questa è la configurazione che suggeriamo per te
        <div className="visually-hidden">{data["NumeroClimatizzatori"] !== false ? " in base al numero di climatizzatori che hai scelto": ""}</div>:</h2>
        <div className={"mb-16 mt-16 " + (data["ModificaConfigurazione"] !== false ? "disa" : "")} >
            <b>{defaultChoice}</b>
        </div> */}

        {/* <ConfigSwitchComponent 
                              cell="ModificaConfigurazione"
                              activationMessage={"E' ora modificare la configurazione dei climatizzatori"}
                              text="Voglio modificare la configurazione*"
                              data={data}
                              onClick={() => {
                                            if (ModificaConfigurazione) {
                                                dispatch(set({
                                                    cell: "ModificaConfigurazione",
                                                    value: false
                                                }))
                                            }
                                            else {
                                                dispatch(set({
                                                    cell: "ModificaConfigurazione",
                                                    value: true
                                                }))
                                            }
                                        }} /> */}

      
        {/* <div className="slider-component">
        <div className="mt-16 mb-16 small-text">*Potremmo aver bisogno di effettuare altri approfondimenti per la tua abitazione ad esempio se ci sono stanze comunicanti, vincoli ambientali o tecnici.</div>
        </div>  */}
    </>)
}



export default ConfiguraRiscaldamentoComponent;