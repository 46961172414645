
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import React, {useState} from 'react';
import fotovoltaico from "../../../icons/icon/fotovoltaico.svg"
import batteria from "../../../icons/icon/batteria.svg"
import Frigo_75 from "../../../icons/products2024/Frigo_75.png"
import Frigo_Bespoke from "../../../icons/products2024/Frigo_Bespoke.png"
import Foto_Batteria from "../../../icons/products2024/Foto_Batteria.png"
import Foto_Solare from "../../../icons/products2024/Foto_Solare.png"
import { GriButton } from '../../dsy-inhouse/GriButtonComponent';

import ProductBox from './ProductBoxComponent';
import ProductModalComponent from './ProductModal';
import { GriCheckboxComponent } from '../../dsy-inhouse/GriCheckboxComponent';


function ProductSelectComponent({ name, cell, description, choices, horizontal, extraClass }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]
    const [show, setShow] = useState(null)

    const images = {Frigo_75, Frigo_Bespoke, Foto_Batteria, Foto_Solare}
    
        return (
            <div >
                <h2 className="title">{name} 
                    {description && <p className="description">{description}</p>}
                </h2>
                <div role="group">
                {choices.map((x, i) => 
                    <div key={i} 
                    role="radio"
                    aria-label={x.text}
                    aria-checked={data[cell] === x.value}>
                        <ProductBox 
                            selected={data[cell] === x.value}
                            {...x} 
                            action={ <div className="d-flex align-items-center">
                        <GriCheckboxComponent
                            id={"id-" + x.value}
                            checked={data[cell] === x.value}
                            onChange={(e) => { 
                                if (data[cell] !== x.value) dispatch(set({cell: cell, value: x.value}))
                                else dispatch(set({cell: cell,value: null}))
                        }}
                            ariaLabel={"Scegli " + name}
                        />
                        <label className="px-2" htmlFor={"id-"+x.value}>Scegli come nuova soluzione</label>
                        </div>} />
                       
                    </div>
                )}
                </div>
                  
                <div id="liveRegion" 
                        aria-live="polite" 
                        aria-atomic="true" 
                        className="visually-hidden"></div>
    
                <ProductModalComponent 
                    show={show !== null}
                    chiudi={() => setShow(null)}
                    extraTitle={choices[show]?.text}
                    serie={choices[show]?.subtitle}
                    tags={choices[show]?.tags}
                    description={choices[show]?.description}
                    image={images[choices[show]?.image]}
                    footnote={choices[show]?.footnote}
                
                />
            </div>
    );
}

export default ProductSelectComponent;